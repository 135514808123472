import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import ReCAPTCHA from 'react-google-recaptcha'
import './styles.css'

export const ContactUs = () => {
  const form = useRef()
  const captchaRef = useRef(null) // Referencia para el reCAPTCHA
  const [captchaVerified, setCaptchaVerified] = useState(false)

  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaVerified(true)
    } else {
      setCaptchaVerified(false)
    }
  }

  const sendEmail = (e) => {
    e.preventDefault()

    if (!captchaVerified) {
      alert('Por favor, verifica el reCAPTCHA antes de enviar.')
      return
    }

    emailjs
      .sendForm('service_8v3gwl7', 'template_rz7uqd7', form.current, {
        publicKey: 'zKKpga6of9pJY-95G',
      })
      .then(
        () => {
          console.log('SUCCESS!')
          form.current.reset()
          setCaptchaVerified(false)

          // Verificar que captchaRef.current no sea nulo antes de llamar a reset()
          if (captchaRef.current) {
            captchaRef.current.reset() // Reiniciar el reCAPTCHA
          }
        },
        (error) => {
          console.log('FAILED...', error.text)
        }
      )
  }

  return (
    <form ref={form} onSubmit={sendEmail} className="field">
      <label></label>
      <input
        className="label"
        type="text"
        name="user_name"
        placeholder="NOMBRE..."
        required
      />
      <label></label>
      <input
        className="label"
        type="email"
        name="user_email"
        placeholder="EMAIL..."
        required
      />
      <label></label>
      <textarea
        className="input"
        name="message"
        placeholder="MENSAJE..."
        required
      />
      <div className="recaptcha-container">
        <ReCAPTCHA
          ref={captchaRef} // Asignamos la referencia aquí
          sitekey="6Le-rbMqAAAAACEKdMYSBPdXzYmee73vCO4iOQWB" // Sustituye con tu clave de sitio
          onChange={handleCaptchaChange}
        />
      </div>
      <div className="send">
        <input type="submit" value="Enviar" />
      </div>
    </form>
  )
}

export default ContactUs

