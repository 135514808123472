import "./App.css";
import Router from '../src/containers/Router/router.jsx'
import ScrollToTopButton from '../src/components/UpButton/index.jsx'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
  font-family: 'Montserrat'; /* Fuente que quieres aplicar */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  }
`

function App() {
  return (
    <div className="App">
      
      <GlobalStyle />
      <Router />
      <ScrollToTopButton />
    </div>
  )
}

export default App;
