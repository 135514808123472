import React from 'react'
import styled from 'styled-components'

const CardContainer = styled.div`
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  margin: 8px;
  background-color: #698669;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  min-height: 560px;
  margin-bottom: 3rem;
  @media (max-width: 768px) {
    max-width: 100%;
    min-height: 430px;
    padding: 10px;
  }
`

const CardImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  @media (max-width: 768px) {
  padding: 1rem;
  }
`

const CardTitle = styled.h3`
  font-size: 1.5rem;
  margin-top: 24px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`

const CardDescription = styled.p`
  font-size: 1rem;
  margin-top: 16px;
  text-align: center;
  color: #efefef;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`

const ResponsiveCard = ({ imageSrc, title, description }) => {
  return (
    <CardContainer>
      <CardImage src={imageSrc} alt={title} />
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
    </CardContainer>
  )
}

export default ResponsiveCard