import { memo } from "react";
import * as S from "./styles";
import Logo_BLANCO from "../../assets/images/Logo_BLANCO.png";
import FC from "../../assets/images/FC.png";
import ig from "../../assets/images/ig.png";
import inst from "../../assets/images/inst.png";
import cookies from "../../assets/others/cookies.pdf";
import legal from '../../assets/others/legal.pdf'
import privacidad from '../../assets/others/privacidad.pdf'


const Footer = () => {
  return (
    <S.FooterContainer>
      {/* //---------Container1------------- */}
      <S.Container1>
        <S.InfoWrap>
          <S.Logo>
            <img src={Logo_BLANCO} alt="" />
          </S.Logo>

          <S.Hour>
            Nos complace la idea de <br />
            que exista la posibilidad <br />
            de poder comenzar una <br />
            relación comercial.
          </S.Hour>
          <S.Media>
            <S.MediaLogo>
              <a href="https://www.facebook.com/friosursca/?locale=es_ES">
                <img src={FC} alt="" />
              </a>
            </S.MediaLogo>
            <S.MediaLogo>
              <a href="https://www.instagram.com/friosurs.c.a/">
                <img src={ig} alt="" />
              </a>
            </S.MediaLogo>
            <S.MediaLogo>
              <a href="https://es.linkedin.com/company/friosur-s-c-a">
                <img src={inst} alt="" />
              </a>
            </S.MediaLogo>
          </S.Media>
        </S.InfoWrap>
      </S.Container1>
      {/* //---------Container2------------- */}
      <S.Container2>
        <S.NavWrap>
          <S.Navbar>
            <S.NavLink>
              <S.Link href="/home">INICIO</S.Link>
            </S.NavLink>
            <S.NavLink>
              <S.Link href="/services">SERVICIOS</S.Link>
            </S.NavLink>
            <S.NavLink>
              <S.Link href="/friosur">FRIOSUR</S.Link>
            </S.NavLink>
            <S.NavLink>
              <S.Link href="/contact">CONTACTO</S.Link>
            </S.NavLink>
          </S.Navbar>
        </S.NavWrap>

        <S.SeparatorWhite />

        <S.InfoWrap>
          <S.Ubi>
            <b> FRIOSUR S.C.A.</b> <br />
            Pol. Industrial “El Manchon”,
            <br />
            c/ San Roque 40, 41940 <br />
            Tomares, Sevilla.
          </S.Ubi>
        </S.InfoWrap>
      </S.Container2>
      {/* //---------Container3------------- */}{' '}
      <S.Container3>
        <S.NavWrap>
          <S.Navbar>
            <S.NavLink>
              <S.Link href={privacidad} download="privacidad.pdf">
                POLITICA DE PRIVACIDAD
              </S.Link>
            </S.NavLink>
            <S.NavLink>
              <S.Link href={cookies} download="cookies.pdf">
                POLITICA DE COOKIES
              </S.Link>
            </S.NavLink>
            <S.NavLink>
              <S.Link href={legal} download="legal.pdf">
                AVISO LEGAL
              </S.Link>
            </S.NavLink>
            <S.NavLink>
              <S.Link href="/contact">CONTACTO</S.Link>
            </S.NavLink>
          </S.Navbar>
        </S.NavWrap>
        {/* --------------//--INFO2-- --------*/}
        <S.SeparatorWhite />
        <S.InfoWrap>
          <S.Hour>
            Horarios de oficinas,de lunes a viernes,
            <br />
            de 7:30 a 15:30 <br />
            <br />
            954 156 725 / 959 302 314 (Sevilla / Huelva). <br />{' '}
            info@friosursca.com
          </S.Hour>
        </S.InfoWrap>
      </S.Container3>
    </S.FooterContainer>
  )
};

export default memo(Footer);
