import { memo } from "react";
import * as S from "./styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ContactUs from "../../components/ContactoForm";
import 'leaflet/dist/leaflet.css';


const Contact = () => {
  return (
    <>
      <S.ContactContainer>
        <Header mode='primary'> </Header>
        <S.Content>
          <S.H1>CONTACTA HOY MISMO</S.H1>
          <S.TextWrap>
            <S.Column1>
              <S.Text>
                <b> FRIOSUR S.C.A.</b> <br />
                Pol. Industrial “El Manchon”,
                <br />
                c/ San Roque 40,
                <br /> 41940 Tomares, Sevilla.
              </S.Text>
              <S.Text>
                Horarios de oficinas,de{' '}
                <b>
                  lunes a viernes,
                  <br />
                  de 7:30 a 15:30.{' '}
                </b>
                <br />
                <br />
                954 156 725 / 959 302 314 (Sevilla / Huelva). <br />{' '}
                info@friosursca.com
              </S.Text>
              <S.Text>
                Nos complace la idea de <br />
                que exista la posibilidad
                <br />
                de poder comenzar una <br />
                relación comercial.
              </S.Text>
            </S.Column1>
            <S.Column2>
              <S.Text>
                Para más información, sobre{' '}
                <b>
                  Frío Industrial,
                  <br /> Mantenimiento y Refrigeración Industrial,{' '}
                </b>
                rellena el siguiente formulario. Estaremos{' '}
                <b>encantados de ayudarte.</b>
              </S.Text>
              <ContactUs />
            </S.Column2>
          </S.TextWrap>
          <S.Ubi>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5280.063075738994!2d-6.031642922845762!3d37.37765583493423!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd126cf4c2f844d7%3A0xb19b94b594100b75!2sC.%20San%20Roque%2C%2040%2C%2041940%20Tomares%2C%20Sevilla!5e1!3m2!1ses!2ses!4v1736508844728!5m2!1ses!2ses"
              title="Mapa de ubicación en Tomares"
              width="1440"
              height="420"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </S.Ubi>
        </S.Content>
        <Footer />
      </S.ContactContainer>
    </>
  )
};




export default memo(Contact);
