import styled, { css } from 'styled-components'

export const Header = styled.div`
  z-index: 1000;
  align-items: center;
  display: flex;
  width: 100vw;
  max-width: 100%;
  max-height: 167px;
  position: fixed;
  color: #fff;
  /* background-color: rgba(255, 255, 255, 0.3); */
  font-family: k2d;
  justify-content: center;
  transition: background-color 0.4s ease;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);

  ${(props) =>
    props.mode === 'primary' &&
    css`
      background-color: #78997dd4;
    `}

  ${(props) =>
    props.mode === 'secondary' &&
    css`
      background-color: rgba(255, 255, 255, 0.3);
    `}

  @media (max-width: 1026px) {
    /* max-height: 100px; */
  }
`

export const HeaderContent = styled(Header)`
  position: static;
  width: 1440px;
  justify-content: space-between;
  /* border: 5px solid red; */
  background-color: #78997d00;
  backdrop-filter: none;
`

export const Logo = styled.div`
  align-items: center;
  display: flex;
  gap: 50px;
  /* margin-left: 25px; */
  margin: 12px;
  margin-left: 35px;
  img {
    width: 65%;
    max-width: 200px;
    min-width: 100px;
  }
  @media (max-width: 1026px) {
    margin-left: 12px;
    img {
      width: 40%;
    }
  }
  @media (max-width: 900px) {
    display: none;
  }
`
export const Dossier = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer; /* Cambia el cursor para indicar que es clickeable */
  background-color: ${(props) => (props.isClicked ? '#e0e0e0' : 'transparent')};
  img {
    margin-left: 1rem;
    width: 100%;
    max-width: 180px;
    min-width: 80px;
    transition: transform 0.2s; /* Transición suave */
    &:hover img {
      transform: scale(
        1.05
      ); /* Aumenta ligeramente la imagen al pasar el mouse */
    }
  }
  @media (max-width: 492px) {
    img {
      width: 100%;
      margin-left: 0px;
      padding: 6px;
    }
  }
`

export const Navbar = styled.a`
  /* border: 1px solid red; */
  justify-content: flex-end;
  display: flex;
  margin-right: 1%;
  font-size: 16px;
  /* gap: 40px; */
  height: 100%;
  width: 100%;
  text-shadow: 1px 1px 2px #666666;
  list-style: none;
  font-weight: 600;
  transition: transform 0.3s ease;
  @media (max-width: 900px) {
    width: 100%;
    justify-content: space-around;
    height: 4rem;
    /*  */
    /* gap: 8px; */
  }
  @media (max-width: 492px) {
    font-size: 13px;
  }
`

export const NavLink = styled.li`
  align-items: center;
  display: flex;
`

export const Link = styled.a`
  border-radius: 10px;
  color: #fff;
  text-decoration: none;
  width: auto;
  padding: 0.5em;
  cursor: pointer;
  transition: 0.5s all ease;
  &:hover {
    color: #dcdcdc;
    background-color:rgba(68, 104, 68, 0.61);
    transition: 0.5s all ease;
  }
  @media (max-width: 1026px) {
    /* padding: 0rem; */
    /* margin: 3px; */
  }
`

export const CloseIcon = styled.div`
  /* position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  cursor: pointer;
  color: #fff; */
  /* @media (min-width: 1201px) {
    display: none;
  } */
`
export const HamburgerIcon = styled.div`
  /* display: none;
  @media (max-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 2rem;
    margin-right: 45px;
  } */
`
