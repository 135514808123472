import { memo, useCallback , useState  } from 'react'
import { useNavigate } from 'react-router-dom'
import * as S from './styles'
import Logo_FRIOSUR from '../../assets/images/Logo_FRIOSUR.png'
import dossier from '../../assets/images/dossier.png'
import Dossier from '../../assets/others/Dossier.pdf'
// import { FaBars, FaTimes } from 'react-icons/fa'


const Header = ({

  mode,
}) => {
  // Change color when scrolling
  // const [menuOpen, setMenuOpen] = useState(false)
  const navigate = useNavigate()

  const handleGoToHome = useCallback(() => {
    navigate('/home')
    window.scrollTo(0, 0)
  }, [navigate])

  const handleGoToServices = useCallback(() => {
    navigate('/services')
    window.scrollTo(0, 0)
  }, [navigate])

  const handleGoToContact = useCallback(() => {
    navigate('/contact')
    window.scrollTo(0, 0)
  }, [navigate])

  const handleGoToFriosur = useCallback(() => {
    navigate('/friosur')
    window.scrollTo(0, 0)
  }, [navigate])
  const [isClicked, setIsClicked] = useState(false)

  const handleDossierClick = () => {
    setIsClicked(true)
    // Inicia la descarga del PDF
    const link = document.createElement('a')
    link.href = Dossier //  Cambia esto por la ruta de tu PDF
    link.download = 'dossier.pdf'
    link.click()

    // Opcionalmente, puedes volver a poner isClicked en false después de un tiempo
    setTimeout(() => setIsClicked(false), 300) // Estilo se quita después de 300ms
  }
  return (
    <S.Header mode={mode}>
      <S.HeaderContent>
        <S.Logo>
          <img src={Logo_FRIOSUR} width alt="" onClick={handleGoToHome} />
        </S.Logo>

        <S.Navbar>
          <S.NavLink>
            <S.Link onClick={handleGoToHome}>INICIO</S.Link>
          </S.NavLink>
          <S.NavLink>
            <S.Link onClick={handleGoToServices}>SERVICIOS</S.Link>
          </S.NavLink>
          <S.NavLink>
            <S.Link onClick={handleGoToFriosur}>EMPRESA</S.Link>
          </S.NavLink>
          <S.NavLink>
            <S.Link onClick={handleGoToContact}>CONTACTO</S.Link>
          </S.NavLink>
          <S.Dossier isClicked={isClicked} onClick={handleDossierClick}>
            <img src={dossier} alt="Dossier" />
          </S.Dossier>
        </S.Navbar>
      </S.HeaderContent>
    </S.Header>
  )
}

export default memo(Header)
